@media print {
  @page {
    margin: 0;
  }

  body {
    background: rgba(0, 0, 0, 0) none;
  }

  body[data-pdfjsprinting] > :not(#printContainer) {
    display: none;
  }

  body[data-pdfjsprinting] > #printContainer {
    display: block;
  }

  #printContainer {
    height: 100%;
  }
  
  #printContainer > .printedPage {
    page-break-after: always;
    page-break-inside: avoid;

    /* The wrapper always cover the whole page. */
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  #printContainer > .printedPage :is(canvas, img) {
    /* The intrinsic canvas / image size will make sure that we fit the page. */
    max-width: 100%;
    max-height: 100%;

    direction: ltr;
    display: block;
  }
}

#printContainer {
  display: none;
}

.pdf-viewer-modal-parent-dark .ant-modal-content {
  padding: 0px;
  background: black;
}

.pdf-viewer-modal-parent .ant-modal-content {
  padding: 0px;
  background: white;
}

.original-viewer {
  border-radius: 5px;
}

.pdf-viewer-parent {
  border: 1px solid #d3d3d3;
  background-color: rgba(0, 0, 0, 0.02);
  /* min-width: 1190px; */
  /* min-width: 1024px; */
  height: 810px;
}

.modal-viewer {
  border-radius: 0px;
  border: none;
}

.loading-bar-container {
  /*border-top: 1px solid #d3d3d3;
  background: rgba(0, 0, 0, 0.02);*/
  height: 15px;
}

.loading-bar-container .ant-progress-inner {
  vertical-align: top !important;
}

.pdf-viewer-ref-container {
  overflow: auto;
  position: absolute;
  width: 100%;
  height: 760px;
  border-top: 1px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  background: rgba(0, 0, 0, 0.02);
}

.pdf-loading-style {
  position: absolute;
  padding-top: 760px;
}

.pdf-viewer-bookmarks-container {
  overflow: auto;
  height: 760px;
  border-top: 1px solid #d3d3d3;
  border-right: 2px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  padding-top: 10px;
  background: rgba(0, 0, 0, 0.02);
}

.pdf-viewer-error {
  height: 760px;
  border-top: 1px solid #d3d3d3;
  border-right: 2px solid #d3d3d3;
  border-bottom: 1px solid #d3d3d3;
  background: rgba(0, 0, 0, 0.02);
}

.pdf-bookmarks-menu {
  background: transparent;
}

.pdf-bookmarks-menu .ant-tree {
  border-radius: 0px;
  border: none;
}

.pdf-bookmarks-menu .ant-tree-list {
  background: rgb(0, 0, 0, 0.04) !important;
}

.pdf-bookmarks-menu .ant-tree .ant-tree-node-content-wrapper {
  background: transparent;
}

.pdf-bookmarks-menu .ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: transparent;
  color: #1890ff;
}

.pdf-bookmarks-menu
  .ant-tree
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: transparent;
}

.pdf-bookmarks-menu .ant-tree-list-holder-inner {
  background: transparent;
}

.pdf-bookmarks-menu .ant-tree-title {
  background: transparent;
}

.pdf-bookmarks-menu .ant-tree-show-line .ant-tree-switcher {
  background: transparent;
}

.pdf-viewer-parent-dark {
  border: 1px solid #424242;
  background-color: #161722;
  /* min-width: 1190px; */
  min-width: 1024px;
  height: 810px;
}
.pdf-viewer-ref-container-dark {
  overflow: auto;
  position: absolute;
  width: 100%;
  height: 760px;
  border-top: 1px solid #424242;
  border-bottom: 1px solid #424242;
  background: #141414;
}
.pdf-viewer-bookmarks-container-dark {
  overflow: auto;
  height: 760px;
  border-top: 1px solid #424242;
  border-right: 2px solid #424242;
  border-bottom: 1px solid #424242;
  padding-top: 10px;
  background: #141414;
}
.pdf-viewer-error-dark {
  height: 760px;
  border-top: 1px solid #424242;
  border-right: 2px solid #424242;
  border-bottom: 1px solid #424242;
  background: #141414;
}

.pdf-bookmarks-menu-dark {
  background: #141414;
}

.pdf-bookmarks-menu-dark .ant-tree .ant-tree-node-content-wrapper {
  background: #141414;
}

.pdf-bookmarks-menu-dark .ant-tree .ant-tree-node-content-wrapper:hover {
  background-color: #141414;
  color: #1890ff;
}

.pdf-bookmarks-menu-dark
  .ant-tree
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: #141414;
}

.pdf-bookmarks-menu-dark .ant-tree-list-holder-inner {
  background: #141414;
}

.pdf-bookmarks-menu-dark .ant-tree-title {
  background: #141414;
}

.pdf-bookmarks-menu-dark .ant-tree-show-line .ant-tree-switcher {
  background: #141414;
}

.ant-row {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.ant-col:first-child {
  padding-left: 0 !important;
}

.ant-col:last-child {
  padding-right: 0 !important;
}
