.rightClickPopup {
  background-clip: padding-box;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  outline: none;
  position: fixed;
  margin: 0px 0px;
  padding: 0px 0px;
  overflow: hidden;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 99999; /* Display it above other elements */
}

.rightClickPopup .ant-btn:hover,
.rightClickPopup .ant-btn:focus {
  border-color: transparent !important;
  background-color: transparent !important;
  background: transparent !important;
  color: rgba(0, 0, 0, 0.85) !important;
}

.rightClickPopup .ant-menu-item:hover {
  border-color: rgba(0, 0, 0, 0.05) !important;
  background-color: rgba(0, 0, 0, 0.05) !important;
}

.rightClickPopup .ant-menu-item {
  clear: both;
  color: rgba(0, 0, 0, 0.85);
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  display: flex;
  align-items: center;
  margin: 5px 0px !important;
  padding: 0px 5px;
  height: 31px;
}
