td.ant-table-cell.pinned-style.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
    border-inline-end: 1px solid #f0f0f0;
}
/* 
td.ant-table-cell.pinned-style.ant-table-cell-fix-right.ant-table-cell-fix-right-first.ant-table-cell-fix-sticky {
    border-left: none !important;
} */

th.ant-table-cell.second-last-column.ant-table-column-has-sorters {
    border-right: none !important;
}

.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >thead>tr >.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >thead>tr >.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >thead>tr >.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-summary >table >thead>tr >.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tbody>tr >.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >tbody>tr >.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tbody>tr >.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-summary >table >tbody>tr >.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-content >table >tfoot>tr >.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-header >table >tfoot>tr >.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-body >table >tfoot>tr >.ant-table-cell-fix-right-first::after,
.ant-table-wrapper .ant-table.ant-table-bordered >.ant-table-container >.ant-table-summary >table >tfoot>tr >.ant-table-cell-fix-right-first::after,
td.ant-table-cell.pinned-style.ant-table-cell-fix-right.ant-table-cell-fix-right-first.ant-table-cell-fix-sticky {
    border-inline-end: none !important;
    border-inline-end-width: 0 !important;
    border-inline-end-style: none !important;
    border-inline-end-color: transparent !important;
}
