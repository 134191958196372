.editable-cell-value-wrap,
.editable-cell-value-wrap-empty {
  cursor: pointer;
}

.editable-cell-value-wrap:hover,
.editable-cell-value-wrap-empty:hover {
  border: 2px solid #d5dde6;
  border-radius: 6px;
}

td.ant-table-cell:has(div.editable-cell-value-wrap),
td.ant-table-cell:has(div.editable-cell-value-wrap-empty),
td.ant-table-cell:has(div.editable-cell-value) {
  padding: 2px 2px !important;
}

.editable-cell-value-wrap,
.editable-cell-value-wrap-empty,
.editable-cell-child-class {
  height: 24px;
}

.editable-cell-value-wrap:not(:hover) {
  padding: 2px 2px !important;
}

.editable-cell-value input {
  padding: 0px 0px !important;
  font-size: 13px !important;
}

.editable-cell-value div.ant-form-item {
  margin-bottom: 0px;
}

