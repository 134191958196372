@media (max-width: 768px) {
  .pending-shipment-class {
    width: calc(100vw - 250px);
    max-width: 315px;
    min-width: 280px;
  }
}

@media ((max-width: 10000px) and (min-width: 768px)) {
  .pending-shipment-class {
    width: calc(100vw - 500px);
    max-width: 700px;
  }
}

.no-left-padding {
  padding-left: 0px !important;
}

:where(.change-form-colon-padding).ant-form-item
  .ant-form-item-label
  > label::after {
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
}

.tab-box-parent {
  height: 33px;
  position: relative;
  border-bottom: 1px solid #d9d9d9;
}

.tab-box-child {
  display: flex;
  justify-content: center;
  height: 32px;
}

.tab-box-container {
  text-align: center;
  color: #1c4658;
  border-bottom: 2px solid #1c4658;
  display: inline-block;
  height: 32px;
}

.instructions-message {
  font-size: 14px;
  margin-bottom: 10px;
}

.centered-message {
  text-align: center;
}

.login-button {
  /*    width: 200px;*/
  /*    margin-right: 30px;*/
  width: 100%;
  height: 40px;
  background-color: #1c4658;
  border-radius: 2px;
  box-shadow: 0 2px 0 rgba(5, 145, 255, 0.1);
  /*    border-radius: 6px*/
}

.login-button:focus,
.login-button:hover {
  color: #fff;
  background: #304f5c !important;
  border-color: #304f5c !important;
  background-color: #304f5c !important;
}

iframe {
  visibility: hidden;
  display: none;
  overflow: hidden;
  margin: 0;
  padding: 0;
  border: none;
  width: 0;
  height: 0;
}

.side-menu-text {
  color: #1c4658;
  font-weight: 600;
}

.loading-autolink-spin .ant-spin .ant-spin-dot-item {
  background-color: #1c4658 !important;
}

.ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  span.side-menu-text,
.ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  span.side-menu-text
  > .ant-menu-submenu-title,
.ant-menu-light
  .ant-menu-item-selected
  > .ant-menu-title-content
  span.side-menu-text,
.ant-menu-light
  > .ant-menu-item
  .ant-menu-item-selected
  > .ant-menu-title-content
  span.side-menu-text {
  color: #ec3a00;
}

.ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  span.anticon.anticon-right.ant-menu-submenu-expand-icon,
.ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  span.anticon.anticon-right.ant-menu-submenu-expand-icon {
  color: #ec3a00 !important;
}

.ant-menu-light
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  span.anticon,
.ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title
  span.anticon,
.ant-menu-light .ant-menu-item-selected > .ant-menu-title-content span.anticon,
.ant-menu-light
  > .ant-menu-item
  .ant-menu-item-selected
  > .ant-menu-title-content
  span.anticon {
  color: #ec3a00 !important;
  stroke: #ec3a00 !important;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  background: #fff0e6;
  color: #ec3a00 !important;
}

.body-card-content {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 8px;
  margin-right: 8px;
  background: #ffffff;
}

.logged-out-content {
  background: #f5f5f5;
  height: 100vh;
  padding: 200px 32px 200px 32px;
}

@supports (-webkit-touch-callout: none) {
  body {
    background: #f5f5f5;
    /* min-height: 600px; */
  }

  .logged-out-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #root {
    height: 100%;
  }

  .register-page-field-suffix {
    width: 10px;
    margin: 0px 0px 0px 4px;
  }
}

@media only screen and (((max-height: 600px) and (max-width: 1000px) and (orientation: landscape)) or ((max-width: 600px) and (max-height: 1000px) and (orientation: portrait))) {
  body {
    background: #f5f5f5;
    /* min-height: 600px; */
  }

  .logged-out-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #root {
    height: 100%;
  }

  .register-page-field-suffix {
    width: 10px;
    margin: 0px 0px 0px 4px;
  }
}

.body-layout-parent {
  background: #f5f5f5;
  height: calc(100vh - 65px);
  position: relative;
}

.body-header {
  background: #ffffff;
  padding: 12px 0px;
  height: 52px;
  border-bottom: 1px solid #f0f0f0;
}

.scroll-body-layout {
  overflow-y: auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logoImageLoading {
  height: 72px;
  width: 264px;
  margin: 16px;
  /* background-image: url("autolink-logo.png"); */
}

.login-image-sizing {
  display: flex;
  justify-content: center;
  height: 72px;
  /* width: 280px; */
}

.login-sub-message {
  text-align: center;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 45px;
  margin-top: 27px;
}

.centered-box {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fixed-sized-box {
  width: 340px;
}

.loading-indicator-style {
  margin-top: 50px;
}

.ant-table-wrapper .ant-table {
  /* border-right: 1px solid #f0f0f0; */
  border-bottom: 1px solid #f0f0f0;
}

.ant-table-cell:has(.unique-filter-column) {
  margin: 0px 0px !important;
  padding: 0px 0px !important;
  width: 0px !important;
  border-inline-end: none !important;
}

.logoImage {
  height: 37px;
  width: 136px;
  background-image: url("alplus-logo-full-length.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 13px 33px 13px 33px;
}

.logo {
  height: 32px;
  width: 32px;
  background-image: url("autolink-logo-small.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin: 16px auto 12px auto;
}

.header-layout-parent {
  background: #fafbfc;
  position: relative;
  z-index: 1000;
  padding: 0px 0px;
  height: 65px;
  box-shadow: 0 1px 2px 0 #d5dde6, 0 1px 6px -1px #d5dde6, 0 2px 4px 0 #d5dde6;
}

.ant-layout-sider-children
  > .ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light {
  border-inline-end: 0px;
  background: #fafbfc;
}

.ant-layout-sider-trigger {
  border-top: 2px solid #d5dde6;
  border-right: 2px solid #d5dde6;
  background: #fafbfc !important;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title,
.ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title {
  background: #fff0e6;
  color: #ec3a00 !important;
}

.ant-menu-light .ant-menu-submenu-selected > .ant-menu-submenu-title:hover,
.ant-menu-light
  > .ant-menu
  .ant-menu-submenu-selected
  > .ant-menu-submenu-title:hover {
  background-color: #fff0e6;
}

.trigger {
  font-size: 22px;
  /* line-height: 64px; */
  /* padding: 0 24px; */
  cursor: pointer;
  transition: color 0.3s;
  /* background: #002140; */
}

.clickable-avatar-area:hover {
  background: #fafafa;
}

.ant-input-disabled,
.ant-input-number-disabled,
.ant-picker-disabled {
  border: 1px solid #d9d9d9 !important;
}

.ant-select-disabled > .ant-select-selector {
  border: 1px solid #d9d9d9 !important;
}

.ant-radio-disabled > .ant-radio-inner,
.ant-checkbox-disabled > .ant-checkbox-inner {
  border-width: 1px;
  border-color: #d9d9d9;
}

.excluded-doc.ant-btn-circle.ant-btn-sm,
.failed-request.ant-btn-circle.ant-btn-sm {
  background: lightpink;
}

.included-doc.ant-btn-circle.ant-btn-sm,
.complete-request.ant-btn-circle.ant-btn-sm {
  background: lightgreen;
}
.complete-request.ant-btn-circle.ant-btn-sm,
.failed-request.ant-btn-circle.ant-btn-sm {
  cursor: default;
}

.no-selection-dark td {
  background: #141414 !important;
}

.custom-table-component .ant-table-row-selected td.ant-table-cell {
  background: #e6f4ff !important;
}
.remove-colon .ant-form-item .ant-form-item-label >label::after {
  content: "" !important;
  position: relative;
  margin-block: 0;
  margin-inline-start: 2px;
  margin-inline-end: 8px;
}
.custom-table-component .batching-table-excluded-reason td.ant-table-cell {
  background: #ffe1e6 !important;
  font-weight: 500 !important;
  font-style: italic !important;
}

.custom-table-component .no-selection td.ant-table-cell {
  background: white !important;
}

.custom-table-component .no-selection td.ant-table-cell.ant-table-column-sort {
  background: #fafafa !important;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background: #e6e6e6 !important;
  color: #78a1cf;
}

th.ant-table-cell:has(span.ant-dropdown-trigger.active) {
  background: #e6e6e6 !important;
  color: #78a1cf;
}

.custom-table-component span.ant-dropdown-trigger:hover {
  background: transparent !important;
}

/* Makes the built-in scrollbar invisible for ant tables, remove if reverting to built-in virtual tables */
.custom-table-component .ant-table-sticky-scroll,
.custom-table-component .ant-table-sticky-scroll-bar {
  height: 0px !important;
}

.blurry-text {
  color: transparent !important;
  text-shadow: 0 0 10px #000;
  user-select: none;
}

.blurry-text-link {
  color: transparent !important;
  text-shadow: 0 0 10px #1677ff;
  user-select: none;
}

.custom-table-component span.ant-dropdown-trigger.ant-table-filter-trigger {
  margin-inline: 0px;
  padding-right: 2px !important;
  padding-left: 2px !important;
  padding: 0px;
}

.ant-table-wrapper .ant-table-tbody > tr > td:not(.second-last-column) {
  border-right: 1px solid #f0f0f0;
}
